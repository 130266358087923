.questionHeader {
  display: flex;
  justify-content: space-between;
  /* height: 64px; */
  margin-bottom: 36x;
  padding: 12px 0;
  align-items: center;
  z-index: 10;
  /* background-color: #fff; */
}

.menuNav .qTitle {
  color: var(--primary-default);
  font-weight: 600;
  margin-left: 0.5rem;
  padding-top: 2px;
}

.questionHeader .numCont {
  top: 0;
}