.scoreCont{
  margin: 0 auto;
  display: flex;
  justify-content: center;
  position: relative;
}
.scoreCont img {
  width: 100%;
  max-width: 320px;
  margin-bottom: 1rem;
}



.scoreTxTcont{
  display: flex;
  position: absolute;
  top: 0;bottom: 0;right: 0;left: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family:ShareTechMono, Tahoma, Geneva, Verdana, sans-serif;
  letter-spacing: -0.04em;
  color: #fff;
  font-weight: 600;
}
.scoreTxt {
  font-size: 40px;
}
.scoreHLine {
  font-size: 20px;
}
.questionDataCont{
  display: flex;
  padding: 1rem;
}
.qTypeCont{
  flex: 1;
  display: flex;
}
.qcontLabel{
  flex: 1;
  padding-left: 0.5rem;
  color: var(--primary-active)
}
.qCount{
  font-size: 20px;
  font-weight: 600;
}
.qTxtLabel{
  font-size: 14px;
}
.ctasCont{
  margin: 0 1rem;
}

@media screen and (max-width: 400px) {
  .scoreCont img {
    width: 100%;
    max-width: 320px;
    max-height:210px;
    width: auto;
    height: auto;
  }
  .scoreTxt {
    font-size: 35px;
  }
  .scoreHLine {
    font-size: 18px;
  }
}