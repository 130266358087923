ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul {
  position: relative;
  /* width: 300px; */
}

li {
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 100%;
  /* height: 80px; */
  position: relative;
}

.classItem{
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  color: var(--primary-default)
}

.classItem svg {
  margin-right: 0.75rem;
}

.row {
  width: 100%;
  height: 8px;
  background-color: #999;
  border-radius: 10px;
  margin-top: 12px;
}
