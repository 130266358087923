.pctCont {
  position: relative;
  max-width: 400px;
  margin: 0 auto;
}
.pctCont img {
  max-width: 100%;
  padding-top: 47px;
  margin-bottom: 24px;
}
.smallQPict{
  position: absolute;
  bottom: 0;
  right: 0;
}

.ringContainer{
  width: 188px;
  height: 188px;
  position: relative;
  padding-top: 20px;
}
.ring{
  width: 188px;
  height: 188px;
  background-color: #ffe29a;
  border-radius: 50%;
  text-align: center;
  margin-top: 20px;
}

.ringContainer img{
  margin: auto;
  position: absolute;
  left: 50%;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.pgCont{
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.pgBody {
  -webkit-flex: 1 1;
          flex: 1 1;
}
.pgFooter{
  padding: 0 0 2rem 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}
.pgFooter.photoFooter{ 
  -webkit-flex-direction: column; 
          flex-direction: column;

}
.footerCell {
  -webkit-flex: 1 1;
          flex: 1 1;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.cellLeft{
  margin-right: 0.25rem;
}
.cellRight{
  margin-left: 0.25rem;
}

.footerCell .button {
  margin-bottom: 0;
}

.pgFooter .button{
  margin-bottom: 0;
}
.hintCont {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  overflow-y: hidden;
}

.hintBox {
  margin: 1rem;
  padding: 1rem;
  background-color: #fff;
  color: var(--text-default);
  border-radius: 6px;
  background-color: var(--white);
  max-width: 350px;
  max-height: 100%;
  overflow-y: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.hintHead {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 1rem;
}

.hintTitle {
  font-size: 1.25rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
}

.hintCont .button{
  margin: 1rem 0 0 0;
}
.hintPctCont {
  padding: 1.5rem;
}
.hintPctCont img{
  max-width: 100%;
  height: auto;
}
.dlTxt{
  margin-left: 0.5rem;
}

@media (max-height:660px)
{
  .hintPctCont{
    display: none;
  }
}
.icon {
  position: absolute;
  left: 8.33%;
  right: 8.33%;
  top: 8.33%;
  bottom: 8.33%;
}
 .accessibility, .accessibility [class^="icn-"], .accessibility [class*=" icn-"]{
  font-size: 48px;
} 

[class^="icn-"], [class*=" icn-"] {
  font-size: 24px;
} 

.leftIcon, .leftIcon [class^="icn-"], .leftIcon [class*=" icn-"] {
  font-size: 24px;
}
.rightIcon, .rightIcon [class^="icn-"], .rightIcon [class*=" icn-"]{
  font-size: 22px;
}

.menuFootButton, .menuFootButton [class^="icn-"], .menuFootButton [class*=" icn-"]{
  font-size: 22px;
}
.button {
  /* button reset */
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: visible;
  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  background-color: var(--primary-default);
  border: 0;

  /* button styles */
  text-decoration: none;

  padding: 0.75rem 1.25rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  /* height: 3rem; */
  font-weight: 600;
  border-radius: 0.25rem;
  transition: background-color .1s;
  color: #fff;
  margin-bottom: 1rem;
}

.button--small {
  font-size: 1rem;
  height: 2rem;
}
.button--medium {
  font-size: 1rem;
}
/* .button:hover {
  background-color: var(--primary-active);
} */
.button:disabled {
  background-color: rgba(37, 48, 129, 0.5);
}

.button.profile, .photoBg .button.profile {
  background-color: var(--secondary-default);
  color: #fff;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.button.profile .btTxt{
  margin-left: 1rem;
}

.button.profile [class^="icn-"],
.button.profile [class*=" icn-"], 
.final .icn-Icon-User::before
.photoBg .button.profile [class^="icn-"],
.photoBg .button.profile [class*=" icn-"], 
.photoBg .final .icn-Icon-User::before,
.photoBg .button.profile .icn-Icon-Document:before,
.photoBg .button.profile .icn-Icon-Document:before, 
.photoBg .final .icn-Icon-Document:before
{
  color: #fff;
}



.socialLinks {
  width: 100%;
}

.socialLinks .button{
  margin-top: 1rem;
}

.socialLinks .button.nopeBt{
  background: none;
  color: #fff
}

.socialLinks .button.social{
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.socialLinks .button.social .btTxt{
  margin-left: 0.5rem;
}

.button.nopeBt{
  background: none;
  color: var(--primary-active);
}
.hintButton{
  /* width: 30px;
  height: 30px; */
  border: 0;
  /* border-radius: 50%; */
  background: none;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  padding: 0.75rem 1.25rem;
  height: 47px;
  -webkit-align-items: center;
          align-items: center;
  color: white;
}
.hintButton .icn-Icon-Help::before{
  color: white;
  font-size: 30px;
}
.nextButton:disabled {
  background-color: #25308180;
}

.hintLabed{
  color: white;
  margin-left: 9px;
  font-size: 1rem;
}
.txtInput{
  border: solid 1px var(--primary-default);
  background-color: var(--white);
  color: var(--primary-active);
  padding: 13px 189px 13px 12px;
  border-radius: 4px;
  width: 100%;
  font-size: 1rem;
  margin-bottom: 1rem;
}
.txtInput::-webkit-input-placeholder{
  color: var(--grey);
}
.txtInput:-ms-input-placeholder{
  color: var(--grey);
}
.txtInput::placeholder{
  color: var(--grey);
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul {
  position: relative;
  /* width: 300px; */
}

li {
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 100%;
  /* height: 80px; */
  position: relative;
}

.classItem{
  background-color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding: 1rem;
  color: var(--primary-default)
}

.classItem svg {
  margin-right: 0.75rem;
}

.row {
  width: 100%;
  height: 8px;
  background-color: #999;
  border-radius: 10px;
  margin-top: 12px;
}

.nameTxtCont{
  position: relative;
  top: -10%;
}

@media screen and (max-width: 300px) {
  .nameTxtCont{
    position: relative;
    top: -8%;
  }
}
.pointsCont {
  background-color: var(--background-highlight);
  border-radius: 50%;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 188px;
  width: 188px;
  -webkit-justify-content:center;
          justify-content:center;
  -webkit-align-items: center;
          align-items: center;
  margin: 20px auto 102px;
  font-size: 1rem;
  position: relative;
}
.points {
  font-size: 2rem;
  margin-top: -0.3125rem;
  margin-bottom: -0.5rem;
}

.resultPct{
  position: absolute;
  bottom: -80px;
  left: -80px;
  width: 147px;
}

.photoBg .pointsCont{
  background-color: #fff;
  color: var(--primary-active)
}
.questionHeader {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  /* height: 64px; */
  margin-bottom: 36x;
  padding: 12px 0;
  -webkit-align-items: center;
          align-items: center;
  z-index: 10;
  /* background-color: #fff; */
}

.menuNav .qTitle {
  color: var(--primary-default);
  font-weight: 600;
  margin-left: 0.5rem;
  padding-top: 2px;
}

.questionHeader .numCont {
  top: 0;
}
.qIconCont{
  width: 27px;
  height: 27px;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 1px;
}

.diamond.curr #Rechteck_59 {
  fill: var(--secondary-default);
  stroke: var(--secondary-default);
 }

.numCont {
  position: absolute;
  font-size: 12px;
  top: 3px;
  left: 0;
  bottom: 0;
  right: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #fff;
  font-weight: bold;
}
 .overlayCont, .floorOverlay {
  /* position: fixed; //TODO: fix small sceen issues */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
   z-index: 20; 
  overflow: hidden;
  overflow-y: auto;
 }

 .overlayCont {
  background: #fff; 
}

.floorOverlay{
  z-index: 30;
  background: rgba(0,0,0,0.5);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

 .floorBt{
  color: var(--primary-default);
  margin-left: 0.75rem;
  font-size: 0.875rem;
  font-weight: 600;
}

.closeBt{
  padding: 0;
  margin: 0;
  background: none;
  border: none;
}

.mnu {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.menuItemsCont{
  -webkit-flex:1 1;
          flex:1 1;
  overflow-y: auto;
}

.menuNav {
  display: -webkit-flex;
  display: flex;
}

.prefOverlay{
  /* background-color: #eee; */
} 

.overlayFooter{
  margin: 0;
  padding: 20px 69px 20px 15px;
}

.overlyHeaderCont {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 64px;
  margin-bottom: 36x;
  padding: 20px 16px;
  -webkit-align-items: center;
          align-items: center;
}

.rightIcon {
  border: none;
  background: none;
  -webkit-align-self: flex-end;
          align-self: flex-end;
}

.textHeader {
  margin: 0 65px 2px 5px; 
}
.leftIcon {
  margin: 0;
}

.currF {
  border-bottom: 2px solid;
}


.menuFootButton {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; 


  background: none;

  /*/* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal; 

  /*/* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  background-color: none;
  border: 0;

  /*/* button styles */
font-family: OpenSans;
font-size: 14px;
font-weight: 600;
font-stretch: normal;
font-style: normal;
line-height: 1.71;
letter-spacing: -0.06px;
color: #253081
/* padding: 0 1rem;

height: 3rem;
border: none;
font-family: Helvetica, Arial, sans-serif;
 medium;
transition: background-color .1s;
margin-bottom: 1rem; */
}

.footText {
  margin: 0.5rem;
}

/* inherit font & color from ancestor
/* color: #0000; */ /*uncommented  text don't show in storybook*/
/* font: inherit; */ 




.menuItem {
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: none;
  font: inherit;
  line-height: normal;
 
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
 
  -webkit-appearance: none;
  background-color: none;
  border: 0;
 
  
  padding: 0 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  /* height: 3rem; */
  border: none;
  font-family: Helvetica, Arial, sans-serif;
  transition: background-color .1s;
  margin-bottom: 1rem;
  height: 27px;
}

.menuItem--small {
  font-size: 1rem;
  height: 2rem;
}
.menuItem--medium {
  font-size: 1.5rem;
}

.menuItem .icn-Icon-Camera {
  font-size: 26px;
}

.isLocked {
  opacity: 0.2
}

.questionText {
  margin: 0 0 0 0.5rem;
  font-family: OpenSans;
  font-size: 0.85rem;
  font-weight: 600;
  text-align: left;
  word-break: break-word;
  color: var(--primary-default);
}
 
.currentQ [class^="icn-"], .currentQ [class*=" icn-"], .currentQ .icn-Icon-Camera::before {
  color: var(--secondary-default);
}
 

.menContainer {
  /* display: flex; */
  background-color: #fff;
  color: var(--secondary-default);
  margin-top: 34px;
}
.scoreCont{
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  position: relative;
}
.scoreCont img {
  width: 100%;
  max-width: 320px;
  margin-bottom: 1rem;
}



.scoreTxTcont{
  display: -webkit-flex;
  display: flex;
  position: absolute;
  top: 0;bottom: 0;right: 0;left: 0;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-family:ShareTechMono, Tahoma, Geneva, Verdana, sans-serif;
  letter-spacing: -0.04em;
  color: #fff;
  font-weight: 600;
}
.scoreTxt {
  font-size: 40px;
}
.scoreHLine {
  font-size: 20px;
}
.questionDataCont{
  display: -webkit-flex;
  display: flex;
  padding: 1rem;
}
.qTypeCont{
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
}
.qcontLabel{
  -webkit-flex: 1 1;
          flex: 1 1;
  padding-left: 0.5rem;
  color: var(--primary-active)
}
.qCount{
  font-size: 20px;
  font-weight: 600;
}
.qTxtLabel{
  font-size: 14px;
}
.ctasCont{
  margin: 0 1rem;
}

@media screen and (max-width: 400px) {
  .scoreCont img {
    width: 100%;
    max-width: 320px;
    max-height:210px;
    width: auto;
    height: auto;
  }
  .scoreTxt {
    font-size: 35px;
  }
  .scoreHLine {
    font-size: 18px;
  }
}
.debugWin{
  position: absolute;
  top: 0;
  left: 0;
  font-size: 12px;
  background: #fff;
  padding: 10px;
  z-index: 50;
}
.debugCtrl{
  background: #fff;
  padding: 2px;
  position: absolute;
  top: 0;
  right: -10px;
}
/* base css */
:root {
  --primary-default: #253081;
  --primary-active: #161d4f;
  --secondary-default: #c6a76e;
  --white: #ffffff;
  --background-default: #fff8e6;
  --text-default: #43201d;
  --secondary-active: #947b51;
  --grey: #aaaaaa;
  --background-highlight: #ffe29a;
}

/* generic tags */
html, body, #root {
  height: 100%;
}

body {
  font-family: OpenSans, -apple-system, BlinkMacSystemFont, 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  background: #f9fbf8;
  line-height: 1.5;
  background: #fff8e6;
  color: #43201d;
  color: var(--text-default);
}
img 
{
    margin:0; 
    display:block
}
* {
  box-sizing: border-box;
}

/* utlity classes */
.hidden {
  display: none;
}

.frame {
  overflow: hidden;
}

.flexContainer {
  /* height: 100vh; */
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 0 30px;
}

.blankLink{
  text-decoration: none;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


/* 
 * fonts
 */

 @font-face {
  font-family: OpenSans;
  src: local(OpenSans-Regular),
    url(/static/media/OpenSans-Regular.1b0809d5.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: OpenSans;
  src: local(OpenSans-Bold),
    url(/static/media/OpenSans-Bold.dbb97fd9.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: ShareTechMono;
  src: local(ShareTechMono-Regular),
    url(/static/media/ShareTechMono-Regular.8b302ac6.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

h1 {
  font-size: 32px;
  font-family:ShareTechMono, Tahoma, Geneva, Verdana, sans-serif;
  letter-spacing: -0.04em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  color: #43201d;
  color: var(--text-default);
}

h2 {
  font-family:ShareTechMono, Tahoma, Geneva, Verdana, sans-serif;
  font-size: 24px;
  /* font-weight: bold; */
  letter-spacing: -0.04em;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  color: #43201d;
  color: var(--text-default);
  margin-top: 0;
}

.CopyLarge {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #43201d;
  color: var(--text-default);
}

h3 {
  font-size: 20px;
  font-family:ShareTechMono, Tahoma, Geneva, Verdana, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  color: #43201d;
  color: var(--text-default);
  letter-spacing: -0.04em;
}

h2.qHeadline {
  font-family: OpenSans;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: normal;

}
.CopyRegular {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #43201d;
  color: var(--text-default);
}
.CopySmall {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #43201d;
  color: var(--text-default);
}
.hintTitle{
  font-family:ShareTechMono, Tahoma, Geneva, Verdana, sans-serif;
  letter-spacing: -0.04em;
}
/* 
 * pages
 */

 .appContainer{
  position: absolute;
  top: 0;
  max-width: 100vw;
  width: 100vw;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  /* max-height: 900px; */
} 

.mainContainer{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}
.pageCont{
  position: absolute;
  /* margin-top: 50px; */
  left: 1rem;
  right: 1rem;
  bottom: 0;
  top: 0;
}
.contentPage{
  margin:0 1rem;
  padding-top: 2rem;
}
.contentPageFooter{
  margin:0 1rem;
}
.pagesContainer{
  -webkit-flex: 1 1;
          flex: 1 1;
  max-width: 500px;
  /* max-width: 640px; */
  margin: 0 auto;
  padding: 0 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content:flex-start;
          justify-content:flex-start;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  position: relative;
  max-height: 750px;
  /* overflow-x: scroll; */
}

.pageContainer{
  padding-top: 50px;
  height: 100%;
}

.menuContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}



body.photoBg{
  background: rgb(119,129,209);
  background: radial-gradient(circle, #5F679F  0%, #414980 100%);
}

.photoBg .CopySmall,
.photoBg h2,
.photoBg p,
.photoBg .questionHeader .icn-Icon-Camera::before,
.photoBg .icn-Icon-Menu::before,
.photoBg .CopySmall
{
  color: #ffffff;
  color: var(--white)
}
.photoBg .button {
  color: #161d4f;
  color: var(--primary-active);
  background: #ffffff;
  background: var(--white);
}

.langCont {
  margin-top: 2rem;
}
@font-face {
  font-family: 'schoko';
  src:
    url(/static/media/schoko.4394d040.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icn-"], [class*=" icn-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'schoko' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #253081;
}

.profile [class^="icn-"], .profile [class*=" icn-"] {
  color: #fff;
}

.icn-Icon-Download:before {
  content: "\e909";
}
.icn-Icon-Document:before {
  content: "\e90a";
}
.icn-Icon-Layer:before {
  content: "\e91a";
}
.icn-Icon-Chevron-Left:before {
  content: "\e900";
}
.icn-Icon-Arrow-Down:before {
  content: "\e901";
}
.icn-Icon-Arrow-Left:before {
  content: "\e902";
}
.icn-Icon-Arrow-Right:before {
  content: "\e903";
}
.icn-Icon-Arrow-Up:before {
  content: "\e904";
}
.icn-Icon-Chevron-Down:before {
  content: "\e905";
}
.icn-Icon-Chevron-Right:before {
  content: "\e906";
}
.icn-Icon-Chevron-Up:before {
  content: "\e907";
}
.icn-Icon-Like:before {
  content: "\e90b";
}
.icn-Icon-Link:before {
  content: "\e90c";
}
.icn-Icon-Share-Twitter:before {
  content: "\e90d";
}
.icn-Icon-Code:before {
  content: "\e90e";
}
.icn-Icon-Help:before {
  content: "\e90f";
  color: #26327d;
}
.icn-Icon-Search:before {
  content: "\e910";
}
.icn-Icon-Camera:before {
  content: "\e911";
  color: #26327c;
}
.icn-Icon-Close:before {
  content: "\e912";
}
.icn-Icon-Settings:before {
  content: "\e913";
}
.icn-Icon-Menu:before {
  content: "\e914";
  color: #26327d;
}
.icn-Icon-Info:before {
  content: "\e915";
}
.icn-Icon-Share:before {
  content: "\e916";
}
.icn-Icon-Share-Facebook:before {
  content: "\e917";
}
.icn-Icon-Add:before {
  content: "\e918";
}
.icn-Icon-User:before {
  content: "\e919";
}

.splashTtl {
  background: url(/static/media/cartouche.e69b056e.svg) no-repeat;
  background-size: cover;
  width: auto;
  height: auto;
  max-width: 289px;
  max-height: 64px;
  min-height: 64px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: #fff;
  text-transform: uppercase;
  font-family: ShareTechMono;
  letter-spacing: -0.04em;
  font-size: 2rem;
  margin: 0 auto;
}
.splashImgCont {
  margin-top: 1rem;
  position: relative;
}

.langCont {
  margin-top: 2rem;
}

.splashTtlCont{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.splashImg{
  max-width: 320px;
  max-height: 320px;
  height: auto;
  margin: 0 auto;
}
.splashLogoCont{
  margin-top: 2rem;
}
.splashLine{
  display: -webkit-flex;
  display: flex;
  margin-bottom: 0.75rem;
}
.splashLine .splImgCont{
  -webkit-flex: 1 1;
          flex: 1 1;
}
.splashLine img{
  max-width: 138px;
  max-height: 60px;
  height: auto;
  margin: 0 auto;
}
