.button {
  /* button reset */
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: visible;
  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  background-color: var(--primary-default);
  border: 0;

  /* button styles */
  text-decoration: none;

  padding: 0.75rem 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 3rem; */
  font-weight: 600;
  border-radius: 0.25rem;
  transition: background-color .1s;
  color: #fff;
  margin-bottom: 1rem;
}

.button--small {
  font-size: 1rem;
  height: 2rem;
}
.button--medium {
  font-size: 1rem;
}
/* .button:hover {
  background-color: var(--primary-active);
} */
.button:disabled {
  background-color: rgba(37, 48, 129, 0.5);
}

.button.profile, .photoBg .button.profile {
  background-color: var(--secondary-default);
  color: #fff;
  justify-content: flex-start;
}

.button.profile .btTxt{
  margin-left: 1rem;
}

.button.profile [class^="icn-"],
.button.profile [class*=" icn-"], 
.final .icn-Icon-User::before
.photoBg .button.profile [class^="icn-"],
.photoBg .button.profile [class*=" icn-"], 
.photoBg .final .icn-Icon-User::before,
.photoBg .button.profile .icn-Icon-Document:before,
.photoBg .button.profile .icn-Icon-Document:before, 
.photoBg .final .icn-Icon-Document:before
{
  color: #fff;
}



.socialLinks {
  width: 100%;
}

.socialLinks .button{
  margin-top: 1rem;
}

.socialLinks .button.nopeBt{
  background: none;
  color: #fff
}

.socialLinks .button.social{
  justify-content: flex-start;
}

.socialLinks .button.social .btTxt{
  margin-left: 0.5rem;
}

.button.nopeBt{
  background: none;
  color: var(--primary-active);
}