.menuItem {
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: none;
  font: inherit;
  line-height: normal;
 
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
 
  -webkit-appearance: none;
  background-color: none;
  border: 0;
 
  
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 3rem; */
  border: none;
  font-family: Helvetica, Arial, sans-serif;
  transition: background-color .1s;
  margin-bottom: 1rem;
  height: 27px;
}

.menuItem--small {
  font-size: 1rem;
  height: 2rem;
}
.menuItem--medium {
  font-size: 1.5rem;
}

.menuItem .icn-Icon-Camera {
  font-size: 26px;
}

.isLocked {
  opacity: 0.2
}

.questionText {
  margin: 0 0 0 0.5rem;
  font-family: OpenSans;
  font-size: 0.85rem;
  font-weight: 600;
  text-align: left;
  word-break: break-word;
  color: var(--primary-default);
}
 
.currentQ [class^="icn-"], .currentQ [class*=" icn-"], .currentQ .icn-Icon-Camera::before {
  color: var(--secondary-default);
}
 