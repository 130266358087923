.icon {
  position: absolute;
  left: 8.33%;
  right: 8.33%;
  top: 8.33%;
  bottom: 8.33%;
}
 .accessibility, .accessibility [class^="icn-"], .accessibility [class*=" icn-"]{
  font-size: 48px;
} 

[class^="icn-"], [class*=" icn-"] {
  font-size: 24px;
} 

.leftIcon, .leftIcon [class^="icn-"], .leftIcon [class*=" icn-"] {
  font-size: 24px;
}
.rightIcon, .rightIcon [class^="icn-"], .rightIcon [class*=" icn-"]{
  font-size: 22px;
}

.menuFootButton, .menuFootButton [class^="icn-"], .menuFootButton [class*=" icn-"]{
  font-size: 22px;
}