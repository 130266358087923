.nameTxtCont{
  position: relative;
  top: -10%;
}

@media screen and (max-width: 300px) {
  .nameTxtCont{
    position: relative;
    top: -8%;
  }
}