.hintCont {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
}

.hintBox {
  margin: 1rem;
  padding: 1rem;
  background-color: #fff;
  color: var(--text-default);
  border-radius: 6px;
  background-color: var(--white);
  max-width: 350px;
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.hintHead {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.hintTitle {
  font-size: 1.25rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
}

.hintCont .button{
  margin: 1rem 0 0 0;
}
.hintPctCont {
  padding: 1.5rem;
}
.hintPctCont img{
  max-width: 100%;
  height: auto;
}
.dlTxt{
  margin-left: 0.5rem;
}

@media (max-height:660px)
{
  .hintPctCont{
    display: none;
  }
}