.pctCont {
  position: relative;
  max-width: 400px;
  margin: 0 auto;
}
.pctCont img {
  max-width: 100%;
  padding-top: 47px;
  margin-bottom: 24px;
}
.smallQPict{
  position: absolute;
  bottom: 0;
  right: 0;
}

.ringContainer{
  width: 188px;
  height: 188px;
  position: relative;
  padding-top: 20px;
}
.ring{
  width: 188px;
  height: 188px;
  background-color: #ffe29a;
  border-radius: 50%;
  text-align: center;
  margin-top: 20px;
}

.ringContainer img{
  margin: auto;
  position: absolute;
  left: 50%;
  transform-origin: 50% 50%;
  transform: translateX(-50%);
}