.qIconCont{
  width: 27px;
  height: 27px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1px;
}

.diamond.curr #Rechteck_59 {
  fill: var(--secondary-default);
  stroke: var(--secondary-default);
 }

.numCont {
  position: absolute;
  font-size: 12px;
  top: 3px;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
}