.txtInput{
  border: solid 1px var(--primary-default);
  background-color: var(--white);
  color: var(--primary-active);
  padding: 13px 189px 13px 12px;
  border-radius: 4px;
  width: 100%;
  font-size: 1rem;
  margin-bottom: 1rem;
}
.txtInput::placeholder{
  color: var(--grey);
}