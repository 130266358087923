/* base css */
:root {
  --primary-default: #253081;
  --primary-active: #161d4f;
  --secondary-default: #c6a76e;
  --white: #ffffff;
  --background-default: #fff8e6;
  --text-default: #43201d;
  --secondary-active: #947b51;
  --grey: #aaaaaa;
  --background-highlight: #ffe29a;
}

/* generic tags */
html, body, #root {
  height: 100%;
}

body {
  font-family: OpenSans, -apple-system, BlinkMacSystemFont, 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  background: #f9fbf8;
  line-height: 1.5;
  background: #fff8e6;
  color: var(--text-default);
}
img 
{
    margin:0; 
    display:block
}
* {
  box-sizing: border-box;
}

/* utlity classes */
.hidden {
  display: none;
}

.frame {
  overflow: hidden;
}

.flexContainer {
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 30px;
}

.blankLink{
  text-decoration: none;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


/* 
 * fonts
 */

 @font-face {
  font-family: OpenSans;
  src: local(OpenSans-Regular),
    url('./fonts/OpenSans-Regular.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: OpenSans;
  src: local(OpenSans-Bold),
    url('./fonts/OpenSans-Bold.ttf') format("truetype");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: ShareTechMono;
  src: local(ShareTechMono-Regular),
    url('./fonts/ShareTechMono-Regular.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

h1 {
  font-size: 32px;
  font-family:ShareTechMono, Tahoma, Geneva, Verdana, sans-serif;
  letter-spacing: -0.04em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  color: var(--text-default);
}

h2 {
  font-family:ShareTechMono, Tahoma, Geneva, Verdana, sans-serif;
  font-size: 24px;
  /* font-weight: bold; */
  letter-spacing: -0.04em;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  color: var(--text-default);
  margin-top: 0;
}

.CopyLarge {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: var(--text-default);
}

h3 {
  font-size: 20px;
  font-family:ShareTechMono, Tahoma, Geneva, Verdana, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  color: var(--text-default);
  letter-spacing: -0.04em;
}

h2.qHeadline {
  font-family: OpenSans;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: normal;

}
.CopyRegular {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--text-default);
}
.CopySmall {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: var(--text-default);
}
.hintTitle{
  font-family:ShareTechMono, Tahoma, Geneva, Verdana, sans-serif;
  letter-spacing: -0.04em;
}
/* 
 * pages
 */

 .appContainer{
  position: absolute;
  top: 0;
  max-width: 100vw;
  width: 100vw;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /* max-height: 900px; */
} 

.mainContainer{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}
.pageCont{
  position: absolute;
  /* margin-top: 50px; */
  left: 1rem;
  right: 1rem;
  bottom: 0;
  top: 0;
}
.contentPage{
  margin:0 1rem;
  padding-top: 2rem;
}
.contentPageFooter{
  margin:0 1rem;
}
.pagesContainer{
  flex: 1;
  max-width: 500px;
  /* max-width: 640px; */
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  justify-content:flex-start;
  flex-direction: column;
  height: 100%;
  position: relative;
  max-height: 750px;
  /* overflow-x: scroll; */
}

.pageContainer{
  padding-top: 50px;
  height: 100%;
}

.menuContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}



body.photoBg{
  background: rgb(119,129,209);
  background: radial-gradient(circle, #5F679F  0%, #414980 100%);
}

.photoBg .CopySmall,
.photoBg h2,
.photoBg p,
.photoBg .questionHeader .icn-Icon-Camera::before,
.photoBg .icn-Icon-Menu::before,
.photoBg .CopySmall
{
  color: var(--white)
}
.photoBg .button {
  color: var(--primary-active);
  background: var(--white);
}

.langCont {
  margin-top: 2rem;
}