 .overlayCont, .floorOverlay {
  /* position: fixed; //TODO: fix small sceen issues */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
   z-index: 20; 
  overflow: hidden;
  overflow-y: auto;
 }

 .overlayCont {
  background: #fff; 
}

.floorOverlay{
  z-index: 30;
  background: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

 .floorBt{
  color: var(--primary-default);
  margin-left: 0.75rem;
  font-size: 0.875rem;
  font-weight: 600;
}

.closeBt{
  padding: 0;
  margin: 0;
  background: none;
  border: none;
}

.mnu {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.menuItemsCont{
  flex:1;
  overflow-y: auto;
}

.menuNav {
  display: flex;
}

.prefOverlay{
  /* background-color: #eee; */
} 

.overlayFooter{
  margin: 0;
  padding: 20px 69px 20px 15px;
}

.overlyHeaderCont {
  display: flex;
  justify-content: space-between;
  height: 64px;
  margin-bottom: 36x;
  padding: 20px 16px;
  align-items: center;
}

.rightIcon {
  border: none;
  background: none;
  align-self: flex-end;
}

.textHeader {
  margin: 0 65px 2px 5px; 
}
.leftIcon {
  margin: 0;
}

.currF {
  border-bottom: 2px solid;
}

