.pgCont{
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.pgBody {
  flex: 1;
}
.pgFooter{
  padding: 0 0 2rem 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.pgFooter.photoFooter{ 
  flex-direction: column;

}
.footerCell {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
}

.cellLeft{
  margin-right: 0.25rem;
}
.cellRight{
  margin-left: 0.25rem;
}

.footerCell .button {
  margin-bottom: 0;
}

.pgFooter .button{
  margin-bottom: 0;
}