.hintButton{
  /* width: 30px;
  height: 30px; */
  border: 0;
  /* border-radius: 50%; */
  background: none;
  padding: 0;
  display: flex;
  padding: 0.75rem 1.25rem;
  height: 47px;
  align-items: center;
  color: white;
}
.hintButton .icn-Icon-Help::before{
  color: white;
  font-size: 30px;
}
.nextButton:disabled {
  background-color: #25308180;
}

.hintLabed{
  color: white;
  margin-left: 9px;
  font-size: 1rem;
}