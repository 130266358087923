.menuFootButton {
  display: flex;
  justify-content: center;
  align-items: center; 


  background: none;

  /*/* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal; 

  /*/* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  background-color: none;
  border: 0;

  /*/* button styles */
font-family: OpenSans;
font-size: 14px;
font-weight: 600;
font-stretch: normal;
font-style: normal;
line-height: 1.71;
letter-spacing: -0.06px;
color: #253081
/* padding: 0 1rem;

height: 3rem;
border: none;
font-family: Helvetica, Arial, sans-serif;
 medium;
transition: background-color .1s;
margin-bottom: 1rem; */
}

.footText {
  margin: 0.5rem;
}

/* inherit font & color from ancestor
/* color: #0000; */ /*uncommented  text don't show in storybook*/
/* font: inherit; */ 



