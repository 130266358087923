.pointsCont {
  background-color: var(--background-highlight);
  border-radius: 50%;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  height: 188px;
  width: 188px;
  justify-content:center;
  align-items: center;
  margin: 20px auto 102px;
  font-size: 1rem;
  position: relative;
}
.points {
  font-size: 2rem;
  margin-top: -0.3125rem;
  margin-bottom: -0.5rem;
}

.resultPct{
  position: absolute;
  bottom: -80px;
  left: -80px;
  width: 147px;
}

.photoBg .pointsCont{
  background-color: #fff;
  color: var(--primary-active)
}