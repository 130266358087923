@font-face {
  font-family: 'schoko';
  src:
    url('./fonts/schoko.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icn-"], [class*=" icn-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'schoko' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #253081;
}

.profile [class^="icn-"], .profile [class*=" icn-"] {
  color: #fff;
}

.icn-Icon-Download:before {
  content: "\e909";
}
.icn-Icon-Document:before {
  content: "\e90a";
}
.icn-Icon-Layer:before {
  content: "\e91a";
}
.icn-Icon-Chevron-Left:before {
  content: "\e900";
}
.icn-Icon-Arrow-Down:before {
  content: "\e901";
}
.icn-Icon-Arrow-Left:before {
  content: "\e902";
}
.icn-Icon-Arrow-Right:before {
  content: "\e903";
}
.icn-Icon-Arrow-Up:before {
  content: "\e904";
}
.icn-Icon-Chevron-Down:before {
  content: "\e905";
}
.icn-Icon-Chevron-Right:before {
  content: "\e906";
}
.icn-Icon-Chevron-Up:before {
  content: "\e907";
}
.icn-Icon-Like:before {
  content: "\e90b";
}
.icn-Icon-Link:before {
  content: "\e90c";
}
.icn-Icon-Share-Twitter:before {
  content: "\e90d";
}
.icn-Icon-Code:before {
  content: "\e90e";
}
.icn-Icon-Help:before {
  content: "\e90f";
  color: #26327d;
}
.icn-Icon-Search:before {
  content: "\e910";
}
.icn-Icon-Camera:before {
  content: "\e911";
  color: #26327c;
}
.icn-Icon-Close:before {
  content: "\e912";
}
.icn-Icon-Settings:before {
  content: "\e913";
}
.icn-Icon-Menu:before {
  content: "\e914";
  color: #26327d;
}
.icn-Icon-Info:before {
  content: "\e915";
}
.icn-Icon-Share:before {
  content: "\e916";
}
.icn-Icon-Share-Facebook:before {
  content: "\e917";
}
.icn-Icon-Add:before {
  content: "\e918";
}
.icn-Icon-User:before {
  content: "\e919";
}
