.debugWin{
  position: absolute;
  top: 0;
  left: 0;
  font-size: 12px;
  background: #fff;
  padding: 10px;
  z-index: 50;
}
.debugCtrl{
  background: #fff;
  padding: 2px;
  position: absolute;
  top: 0;
  right: -10px;
}