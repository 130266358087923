.splashTtl {
  background: url('/src/imgs/cartouche.svg') no-repeat;
  background-size: cover;
  width: auto;
  height: auto;
  max-width: 289px;
  max-height: 64px;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-transform: uppercase;
  font-family: ShareTechMono;
  letter-spacing: -0.04em;
  font-size: 2rem;
  margin: 0 auto;
}
.splashImgCont {
  margin-top: 1rem;
  position: relative;
}

.langCont {
  margin-top: 2rem;
}

.splashTtlCont{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.splashImg{
  max-width: 320px;
  max-height: 320px;
  height: auto;
  margin: 0 auto;
}
.splashLogoCont{
  margin-top: 2rem;
}
.splashLine{
  display: flex;
  margin-bottom: 0.75rem;
}
.splashLine .splImgCont{
  flex: 1;
}
.splashLine img{
  max-width: 138px;
  max-height: 60px;
  height: auto;
  margin: 0 auto;
}